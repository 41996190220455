import * as amplitude from '@amplitude/analytics-browser';

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  constructor() {}

  initAmplitude() {
    amplitude.init(environment.ampKey);
  }

  trackEvent(eventName: string, isKeyAction = false, eventProperties?: any) {
    const eventData = {
      isKeyAction,
      ...eventProperties,
    };

    eventData.path = window.location.pathname;
    amplitude.track(eventName, eventData);
  }
}
